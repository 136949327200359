$primary: #D3463E;
$secondary: #c25431;

$body-bg: #2b2d30;
$body-color: white;

$font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$h1-color: $body-bg;
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
);

@import "../node_modules/bootstrap/scss/bootstrap";