@media (min-width: 1026px) {
  #grid {
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  #grid>div {
    flex-basis: 22%;
  }
}



@media only screen and (max-device-width: 400px) {

  #grid {
    min-height: 100%;
    justify-content: center;
  }

  #grid>div {}

}


#icon {
  color: --primary;
}


.cardText {
  text-align: center;
  font-style: italic;
  font-size: small;
}

.cardTitle {
  font-size: x-large;
}