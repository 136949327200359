#cartaz {
    padding: 2%;
    background-image: url("../Photos/cartaz.jpg");
    background-size: cover;
    background-position: 0%;
}

#logo{
    width: 10%;
    height: auto;
}

@media (max-width: 700px) {
    #logo{
        width: 40%;
        height: auto;
    }
    
}
